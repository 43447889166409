import React, { useState } from "react";
import "./footer.css";
import { Menu } from "../Menu/Menu";
import inditechlogo from "../../assets/Core/logo.png";
import { socialMedia, socialMediaData } from "../SocialMedia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../../api/axios";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    if (!name || !email) {
      alert("Please fill in both fields!");
      return;
    }
    try {
      const response = await axios.post(
        "/enquiry",
        JSON.stringify({ name, email }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(`Name: ${name}, Email: ${email}`);
      alert(
        `Thank you ${name} for connecting with us! \n Our Team will soon connect with you on ${email}`
      );
      setName("");
      setEmail("");
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      } else {
        console.log("Registration Failed");
      }
      alert(`Please Enter Details Again`);
    }
  };

  return (
    <div className="footer-main">
      <div className="footer-vertical-core">
        <div className="footer-vertical-container">
          <a href="home">
            <img
              className="footer-logo"
              src={inditechlogo}
              alt="Inditech Logo"
            />
          </a>
          <div className="footer-info-container">
            <div className="footer-address-container">
              <h2 className="footer-vertical-heading">Address</h2>
              <p>
                FF 14-15, Scheme no.54, Vijay Nagar,
                <br />
                Indore, Madhya Pradesh - 452010
              </p>
            </div>
            <iframe
              title="Inditech Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.309379634668!2d75.88141877601878!3d22.753897079362982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396302a69398d511%3A0x6f8e5b8453689daa!2sShekhar%20Residency!5e0!3m2!1sen!2sin!4v1714413108066!5m2!1sen!2sin"
              width="100%"
              height="150"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="footer-vertical-container">
          <div className="footer-info-container">
            <div className="footer-quick-container">
              <h2 className="footer-vertical-heading">Quick Links</h2>
              <Menu />
            </div>
          </div>
        </div>
        <div className="footer-vertical-container">
          <div className="footer-info-container">
            {/* <div className="footer-quick-container">
              <h2 className="footer-vertical-heading">Quick Links</h2>
              <Menu />
            </div> */}

            <div className="footer-enquiry-container">
              <h2 className="footer-vertical-heading">Connect with Us</h2>
              <div className="footer-enquiry-input-container">
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Your Name"
                  aria-label="Your Name"
                />
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Enter Your Email ID"
                  aria-label="Your Email"
                />
              </div>
              <button onClick={handleSubmit}>Submit</button>
            </div>
            <div className="footer-socialmedia-icon-container">
              {Object.entries(socialMedia).map(([platform, link], index) => {
                const socialMediaIcon = socialMediaData[platform];
                return (
                  <a
                    key={index}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Follow us on ${platform}`}
                  >
                    <FontAwesomeIcon className="logos" icon={socialMediaIcon} />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <hr className="footer-hr" />
      <p className="footer-bottom-text">
        © 2024 Copyrights by Inditech. All Rights Reserved. Developed by Karan
        Kumar Agrawal
      </p>
    </div>
  );
};

export default Footer;
