import { React, useEffect } from "react";
import "./Home.css";
import inditechlogo from "../../assets/Core/logo.png";
import { useNavigate } from "react-router-dom";
import ImageSlider from "../Gallery/Gallery";
import { productData, responsive } from "./home-data";
import Services from "../Services/Services";
import { Services_Data } from "../Services/servicesData";

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Inditech | Innovative AI & Automation Solutions";
  }, []);

  return (
    <div className="home-main">
      <div className="home-core">
        <div className="home-hero">
          <div className="home-hero-container">
            <img className="home-hero-logo" src={inditechlogo} alt="" />
          </div>
          <div className="home-hero-text-container">
            <h1 className="home-hero-text">
              Where Innovation Meets Ingenuity!
            </h1>
          </div>
        </div>
        <div className="home-bottom-container">
          <button
            className="home-cssbuttons-io-button "
            onClick={() => {
              navigate("/about");
            }}
          >
            Know More
            <div className="home-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                  fill="currentColor"
                  d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                ></path>
              </svg>
            </div>
          </button>
          {/* <button className="home-cssbuttons-io-button " onClick={() => { navigate("/products"); }}>Our Products
            <div className="home-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
            </div>
          </button> */}
        </div>
      </div>
      <div className="home-services-container">
        <Services data={Services_Data.slice(0, 3)} />
        <button
          className="home-cssbuttons-io-button "
          onClick={() => {
            navigate("/services");
          }}
        >
          Show More
          <div className="home-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                fill="currentColor"
                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
              ></path>
            </svg>
          </div>
        </button>
      </div>

      {/* <div className="home-partners-slideshow-container">
        <h1 className="home-cards-row-title" id="title">
          Our Past Clients
        </h1>
        <ImageSlider data={productData} res={responsive} />
      </div> */}
    </div>
  );
};

export default Home;
