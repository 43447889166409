function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context(
    "../../assets/Partners",
    false,
    /\.(png|jpe?g|JPG|webp|jfif)$/
  )
);
console.log(images.length);
const noimg = images.length;

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: noimg,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 5,
  },
};

export const productData = images.map((image, index) => {
  const fileNameWithExtension = image.default
    ? image.default.split("/").pop()
    : image.split("/").pop();
  const [fileName, extension] = fileNameWithExtension.split(".");
  const fullName = [fileName, extension].join(".");

  console.log(fullName);

  return {
    id: index + 1,
    imageurl: image.default || image,
    url: `https://${fullName}`,
  };
});

